@if (hasCameraPermission) {
	<agdir-button (click)="open()" [class.hidden]="doNotRender()" color="outline" icon="photo_camera" />
} @else {
	<span transloco="media.noPermission"></span>
}

@if (showCamera | async) {
	<div class="camera-view">
		<webcam
			#webcam
			(imageCapture)="handleImage($event)"
			[height]="windowSize.height"
			[switchCamera]="nextWebcamObservable"
			[trigger]="triggerObservable"
			[width]="windowSize.width"></webcam>
		<div class="flex items-center justify-center fixed top-1/2 left-0 gap-5 w-full">
			@if (isIdle$ | async) {
				<button (click)="webcam.takeSnapshot()" class="text-lg md:text-2xl">
					<agdir-icon icon="photo_camera" />
				</button>
				<button (click)="changeWebCam(true)" class="text-lg md:text-2xl">
					<agdir-icon icon="camera_front" />
				</button>
				<button (click)="showCamera.next(false)" class="text-lg md:text-2xl">
					<agdir-icon icon="close" />
				</button>
			} @else {
				<agdir-spinner></agdir-spinner>
			}
		</div>
	</div>
}
