


import { ButtonComponent } from '@agdir/ui/button';

import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

@Component({
	selector: 'agdir-bottom-sheet',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NzDrawerModule, ButtonComponent, TranslocoDirective],
	template: `
		<!--		@if (isVisible()) {-->
		<!--			<div class="fixed bottom-0 left-0 width-full">-->
		<nz-drawer
			nzHeight="auto"
			[autofocus]="false"
			[nzClosable]="false"
			nzWrapClassName="agdir-drawer"
			[nzVisible]="isVisible()"
			[nzPlacement]="'bottom'"
		>
			<ng-container *nzDrawerContent>
				<input type="text" class="hidden" [attr.cdkFocusInitial]="true" />
				<div class="flex items-center justify-between mb-1">
					<h3 class="font-bold figma-mobile-header text-center flex-1" [transloco]="title()" [translocoParams]="translationParams()"></h3>
					@if (closeButton()) {
						<agdir-button color="ghost" icon="close" (click)="close()" />
					}
				</div>
				@if (subtitle()) {
					<h4 class="figma-body-text-small-regular text-center " [transloco]="subtitle()"></h4>
				}
				<div class="flex-row-center flex-0 actions mt-1 pt-1">
					<ng-content select="[actions]" />
				</div>
				<div class="flex-col-start gap-2 mt-2 pt-2 border-t">
					<ng-content />
				</div>
			</ng-container>
		</nz-drawer>
		<!--			</div>-->
		<!--		}-->
	`,
	styles: `
		::ng-deep .ant-drawer-mask {
			opacity: 0.5 !important;
			background: #d9d9d9 !important;
		}

		::ng-deep .agdir-drawer {
			@apply rounded-t-[30px];

			& > div {
				@apply rounded-t-[30px];
			}
		}

		h3:empty,
		h4:empty,
		.actions:empty {
			display: none;
		}
	`,
})
export class AgdirBottomSheetComponent {
	closeButton = input<boolean>(false);
	title = input<string>('');
	subtitle = input<string>('');
	isVisible = model<boolean>(false);
	translationParams = input<Record<string, unknown>>({});

	close() {
		this.isVisible.set(false);
	}

	toggle() {
		this.isVisible.set(!this.isVisible());
	}
}
