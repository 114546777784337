import { AgdirCardComponent } from '@agdir/core/angular';
import { I18nService } from '@agdir/i18n/angular';
import { AgdirHttpClient } from '@agdir/services';
import { ButtonComponent } from '@agdir/ui/button';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SECURITY_CONTEXT } from 'ngx-markdown';
import { toLazySignal } from 'ngxtension/to-lazy-signal';
import { BehaviorSubject, combineLatest, delay, switchMap } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

@Component({
	selector: 'agdir-coming-soon',
	template: `
		@if (comingSoonContent$ | async; as comingSoon) {
			@if (isVisibleDelayedAnimation$()) {
				<agdir-card title="importantAgdirInfoBlock.title" [@dismissAnimation]="(isVisible$ | async) ? 'active' : 'dismissed'">
					<div class="prose" [innerHTML]="getSanitizedHtml(comingSoon)"></div>
					<div class="flex-row-end">
						<agdir-button (click)="dismiss(comingSoon)" icon="done" color="primary" label="importantAgdirInfoBlock.ack" />
					</div>
				</agdir-card>
			}
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: SECURITY_CONTEXT, useValue: 'HTML' }],
	imports: [AsyncPipe, ButtonComponent, AgdirCardComponent],
	animations: [
		trigger('dismissAnimation', [
			state(
				'active',
				style({
					opacity: 1,
					height: '*',
				}),
			),
			state(
				'dismissed',
				style({
					paddingTop: 0,
					paddingBottom: 0,
					opacity: 0,
					height: '0px',
					overflow: 'hidden',
				}),
			),
			transition('active => dismissed', [animate('300ms ease-in-out')]),
			transition('dismissed => active', [animate('500ms ease-in-out')]),
		]),
	],
	styles: [
		`
			body:not([data-theme]),
			body[data-theme='blue'],
			body[data-theme='default'] {
				agdir-coming-soon agdir-card {
					@apply bg-blue-100 border-[3px] border-gray-50;
				}
			}
		`,
		`
			body[data-theme='green'] {
				agdir-coming-soon agdir-card {
					@apply bg-lime-100 border-[3px] border-gray-50;
				}
			}

			agdir-coming-soon:empty {
				display: none;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
})
export class ComingSoonComponent {
	dismissed = false;
	private domSanitizer = inject(DomSanitizer);
	private i18nService = inject(I18nService);
	private restService = inject(AgdirHttpClient);

	visibilityCheckTrigger$ = new BehaviorSubject(undefined);

	comingSoonContent$ = this.i18nService.langChanges$.pipe(
		switchMap((lang) => this.restService.get<string>('/gjallarhorn/coming-soon/' + lang)),
		catchError(() => ''),
		shareReplay(1),
	);
	isVisible$ = combineLatest([this.comingSoonContent$, this.visibilityCheckTrigger$]).pipe(
		switchMap(async ([cs]) => {
			if (!cs) {
				return false;
			}
			const storedHash = localStorage.getItem(this.getHashKey());
			if (!storedHash) {
				return true;
			}
			const messageHash = await this.digestMessage(cs);
			return storedHash !== messageHash;
		}),
	);
	isVisibleDelayedAnimation$ = toLazySignal(this.isVisible$.pipe(delay(300)));

	async dismiss(msg: string) {
		localStorage.setItem(this.getHashKey(), await this.digestMessage(msg));
		this.visibilityCheckTrigger$.next(undefined);
	}

	getSanitizedHtml(html: string) {
		return this.domSanitizer.bypassSecurityTrustHtml(html);
	}

	private getHashKey() {
		return `comingSoon:${this.i18nService.getActiveLang()}`;
	}

	private async digestMessage(message: string) {
		if ('subtle' in crypto) {
			return await crypto.subtle.digest('SHA-1', new TextEncoder().encode(message)).then((hashBuffer) =>
				Array.from(new Uint8Array(hashBuffer))
					.map((b) => b.toString(16).padStart(2, '0'))
					.join(''),
			);
		}
		return window.btoa(message);
	}
}
