import { AgdirButtonColor, ButtonComponent } from '@agdir/ui/button';
import { NgClass } from '@angular/common';
import { Component, inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslocoPipe } from '@ngneat/transloco';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AgdirConfirmationConfig } from './confirmation.types';
import { AgdirConfirmationDialogComponent } from './dialog/dialog.component';

const DEFAULT_CONFIG: AgdirConfirmationConfig = {
	title: 'Confirm action',
	message: 'Are you sure you want to confirm this action?',
	icon: {
		show: true,
		name: 'warning',
		color: 'warn',
	},
	actions: {
		confirm: {
			show: true,
			label: 'Confirm',
			color: 'warn',
		},
		cancel: {
			show: true,
			label: 'Cancel',
		},
	},
	dismissible: false,
	fullScreen: true,
};

@Injectable({ providedIn: 'root' })
export class AgdirConfirmationService {
	private readonly matDialog = inject(MatDialog);
	private readonly popupService = inject(AgdirPopupService);

	open(config: AgdirConfirmationConfig = {}, modalConfig: MatDialogConfig = {}): MatDialogRef<AgdirConfirmationDialogComponent> {
		const userConfig = { ...DEFAULT_CONFIG, ...config };
		return this.matDialog.open(AgdirConfirmationDialogComponent, {
			autoFocus: false,
			disableClose: !userConfig.dismissible,
			data: userConfig,
			panelClass: ['agdir-confirmation-dialog-panel', config.fullScreen ? '' : 'skip-overrides'],
			...modalConfig,
		});
	}

	genericDelete(title?: string): Promise<boolean> {
		return new Promise((resolve) => {
			this.popupService.open({
				title,
				message: 'general.deleteConfirmation.subtitle',
				buttons: [
					{
						icon: 'close',
						color: 'outline',
						label: `general.deleteConfirmation.noCancel`,
						onClick: (ref) => {
							ref.destroy();
							resolve(false);
						},
					},
					{
						icon: 'delete',
						color: 'dark-red',
						label: `general.deleteConfirmation.yesDelete`,
						onClick: (ref) => {
							ref.destroy();
							resolve(true);
						},
					},
				],
			});
		});
	}
}

export interface AgdirPopupButton {
	icon?: string;
	color?: AgdirButtonColor;
	size?: 'small' | 'default';
	label: string;
	onClick: (ref: NzModalRef) => void;
}

export interface AgdirPopupConfig {
	title?: string;
	message?: string;
	buttonWrapperClass?: string;
	buttons?: AgdirPopupButton[];
}

@Injectable({ providedIn: 'root' })
export class AgdirPopupService {
	private modal = inject(NzModalService);

	open(config: AgdirPopupConfig): NzModalRef {
		return this.modal.create({
			nzClosable: false,
			nzContent: PopupContentComponent,
			nzData: config,
			nzFooter: null,
		});
	}
}

@Component({
	selector: 'agdir-popup-content',
	template: `
		<div>
			@if (config.title) {
				<h2 class="text-xl font-bold text-center border-b border-gray-200 pb-1">{{ config.title | transloco }}</h2>
			}
			@if (config.message) {
				<p class="mt-3 mb-5 text-center text-lg">{{ config.message | transloco }}</p>
			}
			<div class="flex items-center justify-between" [ngClass]="config.buttonWrapperClass">
				@for (button of config.buttons; track button.label) {
					<agdir-button
						[size]="button.size ?? 'default'"
						[color]="button.color ?? 'secondary'"
						[icon]="button.icon!"
						[label]="button.label | transloco"
						(click)="button.onClick(ref)"
					/>
				}
			</div>
		</div>
	`,
	imports: [ButtonComponent, NgClass, TranslocoPipe],
})
export class PopupContentComponent {
	readonly config: AgdirPopupConfig = inject(NZ_MODAL_DATA);
	readonly ref = inject(NzModalRef);
}
