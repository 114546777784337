import { AgdirIconComponent } from '@agdir/agdir-icons';
import { AgdirFile } from '@agdir/fillagring';
import { ButtonComponent } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, inject, input, model, Pipe, PipeTransform } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { FileService } from './file.service';

import { AgdirConfirmationService } from '@agdir/ui';
@Pipe({
	name: 'fileSize',
	standalone: true,
})
export class FileSizePipe implements PipeTransform {
	transform(bytes: number): string {
		if (!bytes) return '0 B';

		const units = ['B', 'KB', 'MB', 'GB', 'TB'];
		const base = 1024;

		const exponent = Math.floor(Math.log(bytes) / Math.log(base));
		const unit = units[Math.min(exponent, units.length - 1)];

		const size = (bytes / Math.pow(base, exponent)).toFixed(2);

		const formattedSize = parseFloat(size);

		return `${formattedSize} ${unit}`;
	}
}

@Component({
	selector: 'agdir-file-list',
	template: `
		<div class="w-full flex flex-col gap-1">
			@for (file of files(); track $index) {
				<div class="flex items-center flex-row border gap-1 rounded-[5px] p-[6px]">
					@if (file.uploadedAt) {
						<agdir-button (click)="download(file)" color="ghost" size="large" icon="download" class="bg-green-100 rounded-[5px]" />
					}
					<div class="flex-1">
						<div class="text-[16px]">{{ file.name }}</div>
						<div class="text-[16px] text-gray-600">{{ file.size | fileSize }}</div>
					</div>
					@if (showDelete()) {
						<agdir-button (click)="delete(file)" icon="delete" size="large" color="red-ghost" />
					}
				</div>
			} @empty {
				<div class="flex flex-row border-b pb-[5px]">
					<div class="flex items-center justify-center w-full gap-2">
						<agdir-icon icon="info" />
						<div transloco="media.youDontHaveFilesHere"></div>
					</div>
				</div>
			}
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ButtonComponent, TranslocoDirective, AgdirIconComponent, FileSizePipe],
})
export class FileListComponent {
	files = model<AgdirFile[]>();
	fileToDelete = model<AgdirFile>();
	showDelete = input(true);
	fileService = inject(FileService);
	genericAgdirConfirmationService = inject(AgdirConfirmationService);

	async delete(file: AgdirFile) {
		if (await this.genericAgdirConfirmationService.genericDelete('Are you sure you want to delete this file?')) {
			file.softDeleted = true;
			this.fileToDelete.set(file);
			this.files.update((files) => files?.filter((file) => !file.softDeleted) || []);
		}
	}

	async download(file: AgdirFile) {
		const url = await this.fileService.download(file.path);
		window.open(url, '_blank');
	}
}
