import { ButtonComponent } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { AlertType } from './alert.types';
import { AgdirIconComponent } from '@agdir/agdir-icons';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
	selector: 'agdir-alert',
	template: `
		@if (!dismissed()) {
			@if (computedIcon(); as i) {
				<agdir-icon [icon]="i" />
			}

			<div class="flex-col-start flex-1">
				@if (title(); as t) {
					<p [innerHTML]="t | transloco"></p>
				}
				@if (description(); as d) {
					<p [innerHTML]="d | transloco"></p>
				}
				<div>
					<ng-content></ng-content>
				</div>
			</div>
			@if (showAction()) {
				<agdir-button
					(click)="actionTriggered.emit()"
					[disabled]="actionInAction()"
					[isProcessing]="actionInAction()"
					[label]="actionLabel()"
					size="small"
					color="primary"
					class="rounded-1"
				/>
			}

			@if (dismissible()) {
				<agdir-button (click)="actionTriggered.emit()" icon="close" size="small" color="primary" class="rounded-1" />
			}
		}
	`,
	styles: [
		`
			:host {
				line-height: 1.45;
				@apply flex-row-center p-1 rounded-1 gap-1 max-md:text-[12px];
			}
		`,
		`
			agdir-icon {
				min-width: 1em;
			}
		`,
		`
			:host[type='info'] {
				border-color: #ebf1ff;
				background: #f5f8ff;
			}
		`,
		`
			:host[type='error'] {
				border-color: #eca2a2;
				background: #f5d0d0;
			}
		`,
		`
			:host[type='success'] {
				border-color: #c7e8bf;
				background: #e2f3dd;
			}
		`,
		`
			:host[type='warning'] {
				border-color: #fcf0c5;
				background: #fdf7e2;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ButtonComponent, AgdirIconComponent, TranslocoPipe],
})
export class AgdirAlertComponent {
	icon = input<string | null>('');
	computedIcon = computed<string | null>(() => {
		const icon = this.icon();
		if (icon) {
			return icon;
		}
		if (this.type() === 'warning' && icon !== null) {
			return 'warning';
		}
		if (this.type() === 'error' && icon !== null) {
			return 'error';
		}
		if (this.type() === 'success' && icon !== null) {
			return 'check_circle';
		}
		if (this.type() === 'info' && icon !== null) {
			return 'info';
		}
		return null;
	});

	title = input<string>();
	description = input<string | undefined>(undefined);
	type = input<AlertType>('info');

	actionLabel = input<string>('');
	actionInAction = input(false, { transform: (value: 'true' | 'false' | boolean) => value === 'true' || value === true });
	showAction = input(false, { transform: (value: 'true' | 'false' | boolean) => value === 'true' || value === true });

	dismissed = input(false, { transform: (value: 'true' | 'false' | boolean) => value === 'true' || value === true });
	withBg = input(true, { transform: (value: 'true' | 'false' | boolean) => value === 'true' || value === true });
	withPadding = input(true, { transform: (value: 'true' | 'false' | boolean) => value === 'true' || value === true });
	dismissible = input(false, { transform: (value: 'true' | 'false' | boolean) => value === 'true' || value === true });

	readonly dismissedChanged = output<boolean>();
	readonly actionTriggered = output<void>();
}
